import React from 'react'
import { Twitter, Dribbble, Instagram, LinkedIn, Pinterest, Thread } from '../Icons'

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-left">
                    <a href="https://www.priyaminnovations.com/">
                        <img src="Images/logo 2.svg" alt="tabjockey_logo" />
                    </a>
                </div>
                <div className="footer-center">
                    <div className="icons">
                        <a
                            href="https://twitter.com/Priyam_Inn"
                            target="blank"
                            title="Twitter"
                        >
                            <Twitter fill="#ffffff" width="23" height="23" />
                        </a>
                        <a
                            href="https://dribbble.com/PriyamInnovations"
                            target="blank"
                            title="Dribbble"
                        >
                            <Dribbble fill="#ffffff" width="23" height="23" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/77714375/admin/feed/posts/"
                            target="blank"
                            title="LinkedIn"
                        >
                            <LinkedIn fill="#ffffff" width="19" height="19" />
                        </a>
                        <a
                            href="https://in.pinterest.com/priyaminnovations/"
                            target="blank"
                            title="Pinterest"
                        >
                            <Pinterest fill="#ffffff" width="20" height="20" />
                        </a>
                        <a
                            href="https://www.instagram.com/priyam_innovations/"
                            target="blank"
                            title="Instagram"
                        >
                            <Instagram fill="#ffffff" width="19" height="19" />
                        </a>
                        <a
                            href="https://www.threads.net/@priyam_innovations_"
                            target="blank"
                            title="thread"
                        >
                            <Thread fill="#ffffff" width="19" height="19" />
                        </a>
                    </div>
                </div>
                <div className="footer-right">
                    <a rel="noreferrer" href="mailto:contact@priyaminnovations.com" target='_blank'>
                        <p>Send Email</p>
                    </a>
                    <a rel="noreferrer" href="https://calendly.com/priyaminnovations" target='_blank'>
                        <p>Book Calendly</p>
                    </a>
                </div>
            </div>
            <p className='made-by'>Made With Love ❤️ By <a href="https://www.priyaminnovations.com/">Priyam Innovations</a></p>
        </footer>
    )
}

export default Footer