import React from 'react'
import { Navbar, HeroSection } from './Components'
import Features from './Components/Features'
import UpcomingFeature from './Components/UpcomingFeature'
import Footer from './Components/Footer'

const App = () => {
  return (
    <div className='root-container'>
      <Navbar />
      <HeroSection />
      <Features />
      <UpcomingFeature />
      <Footer />
    </div>
  )
}

export default App