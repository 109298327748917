import React from 'react'

const FeatureCard = ({ image, alt, heading, content, imageBackground }) => {
    return (
        <div 
        className='feature-card'
        style={{boxShadow: `2px 3px 250px 0px ${imageBackground}`}}
        >
            <img
                style={{ backgroundColor: imageBackground }}
                src={image}
                alt={alt}
            />
            <h3>{heading}</h3>
            <p>{content}</p>
        </div>
    )
}

export default FeatureCard