import React from 'react'
import FeatureCard from './FeatureCard'

const UpcomingFeature = () => {
    return (
        <div id='upcoming-feature-section' className='feature-container upcoming'>
            <div className="container">
                <h1>Upcoming Features</h1>
                <div className="feature-cards">
                    <FeatureCard
                        image="Images/feature-open.svg"
                        alt="close_feature_icon"
                        heading="Open Tab"
                        content="Seamlessly switch between multiple tabs with ease"
                        imageBackground="#A259FF"
                    />
                    <FeatureCard
                        image="Images/feature-close.svg"
                        alt="mute_feature_icon"
                        heading="Close All Tabs"
                        content="Close All Audio Tabs feature for quick and hassle-free tab management"
                        imageBackground="#FD5B1C"
                    />
                    <FeatureCard
                        image="Images/feature-mute.svg"
                        alt="unmute_feature_icon"
                        heading="Mute All Tabs"
                        content="Mute all tabs at once for uninterrupted browsing"
                        imageBackground="#3CCDF2"
                    />
                </div>
            </div>
        </div>
    )
}

export default UpcomingFeature