import React from 'react'

const HeroSection = () => {
    return (
        <div id='home-section' className='hero-section'>
            <img src="Images/stars.svg" alt="stars" />
            <h1>TABJOCKEY</h1>
            <h3>Silence the Noise: Mute All Tabs with Ease</h3>
            <div className='hero-buttons'>
                <a rel="noreferrer" href="https://chrome.google.com/webstore/detail/tabjockey-mute-all-tabs-w/geelfcfpkijfgaaeleaddghneokmbfcc?hl=en" target='_blank'>
                    <button>Go to Extension</button>
                </a>
                <a rel="noreferrer" target='_blank' href="https://bmc.link/pinovate">
                    <button id='buy-me-btn'>Buy me a coffee</button>
                </a>
            </div>
            <div className="random-images">
                <img className='mic' src="Images/mic.svg" alt="mic" />
                <img className='video-btn' src="Images/video-btn.svg" alt="video-btn" />
                <img className='message' src="Images/message.svg" alt="message" />
            </div>
        </div>
    )
}

export default HeroSection
