import React from 'react'
import FeatureCard from './FeatureCard'

const Features = () => {
    return (
        <div id='feature-section' className='feature-container'>
            <div className="container">
                <h1>Features</h1>
                <div className="feature-cards">
                    <FeatureCard
                        image="Images/feature-mute.svg"
                        alt="mute_feature_icon"
                        heading="Mute Tabs"
                        content="Mute the chaos, silence tabs with a single click. Detect and Mute tabs playing ad videos also"
                        imageBackground="#3CCDF2"
                    />
                    <FeatureCard
                        image="Images/feature-unmute.svg"
                        alt="unmute_feature_icon"
                        heading="Unmute Tabs"
                        content="Seamlessly toggle between muting and unmuting tabs with a single click"
                        imageBackground="#A259FF"
                    />
                    <FeatureCard
                        image="Images/feature-close.svg"
                        alt="close_feature_icon"
                        heading="Close Tabs"
                        content="Easily close unwanted tabs to focus on what matters most"
                        imageBackground="#FD5B1C"
                    />
                </div>
            </div>
        </div>
    )
}

export default Features