import React, { useEffect, useState } from 'react'

const Navbar = () => {
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setDeviceWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (deviceWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [deviceWidth]);

    return (
        <div className='navbar-section'>
            <div className="logo-container">
                <img src="Images/logo1.svg" alt="tab-jockey-logo" />
                <p>TAB JOCKEY</p>
            </div>
            {
                !isMobile ?
                    <>
                        <ul>
                            <li><a href="#home-section">Home</a></li>
                            <li><a href="#feature-section">Features</a></li>
                            <li><a href="#upcoming-feature-section">Upcoming</a></li>
                        </ul>
                        <a rel="noreferrer" target='_blank' href="https://www.priyaminnovations.com/">
                            <button>Contact Us</button>
                        </a>
                    </> :
                    <img id='menu-icon' onClick={() => setShowMenu(true)} src="Images/menu.svg" alt="header_menu_icon" />
            }
            <div className={`mobile-menu-bar ${showMenu ? "active" : ""}`}>
                <img className='cross-icon' onClick={() => setShowMenu(false)} src="Images/cross.svg" alt="cross_icon" />
                <ul>
                    <li onClick={() => setShowMenu(false)}><a href="#home-section">Home</a></li>
                    <li onClick={() => setShowMenu(false)}><a href="#feature-section">Features</a></li>
                    <li onClick={() => setShowMenu(false)}><a href="#upcoming-feature-section">Upcoming</a></li>
                    <a rel="noreferrer" target='_blank' href="https://bmc.link/pinovate">
                        <button id='buy-me-btn'>Buy me a coffee</button>
                    </a>
                    <a rel="noreferrer" target='_blank' href="/">
                        <button>Go To Extension</button>
                    </a>
                    <a rel="noreferrer" target='_blank' href="https://www.priyaminnovations.com/">
                        <button>Contact Us</button>
                    </a>
                </ul>
            </div>
        </div>
    )
}

export default Navbar
